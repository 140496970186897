/* eslint-disable camelcase */
/* version 1.0 */

const DISABLE_COMMERCE = false;
var VARIANT_PRODUCT = true;
var selectedSku_dictionary = {};
var cart_timer_interval = null;
var startingTime = 0;



class ProductListingTemplateGloBrochurewareRS extends BATComponent {
	beforeLoad() {
		this.data = { ...this.data, disableCommerce: DISABLE_COMMERCE };
	}

	static get cartButtons() {
		return [...document.querySelectorAll('[data-cta-type="cart"]')];
	}

	static get paginationButtons() {
		return [...document.querySelectorAll('[data-parameters="page"]')];
	}

	static get notificationButtons() {
		return [
			...document.querySelectorAll('[data-parameters="notification"]'),
		];
	}

	static get cartMessageElements() {
		return [
			...document.querySelectorAll('.bat-productlistings-card-message'),
		];
	}

	static get quantityWrappers() {
		return [
			...document.querySelectorAll('.bat-productlistings-card-quantity'),
		];
	}

	static get closeNotificationButtons() {
		return [
			...document.querySelectorAll('[data-cta-type="closeNotification"]'),
		];
	}

	static get variantSelectElements() {
		return [...document.querySelectorAll('.bat-productlistings-card-options-variants-button')];
	}

	static get listSelectElements() {
		return [...document.querySelectorAll('.bat-productlistings-card-details-image a')];
	}

	afterLoad() {

		this.$el = $(this);
		const hasLoaded = this.$el.hasClass('loaded');

		const $genericLinks = this.querySelectorAll(
			'a[data-cta-type="internal"]'
		);

		$genericLinks.forEach((el) => el.classList.add('active'));

		const urlParams = new URLSearchParams(window.location.search);
		const searchString = urlParams.get('search');
		switch (this.data.dataSource) {
			case 'filter':
				this.productListListener = this.getProductList.bind(this);
				document.addEventListener(
					'filterPLP',
					this.productListListener,
					false
				);
				break;
			case 'related_products':
				this.getProductList({
					detail: {
						sku: this.data.productSKU,
					},
				});
				break;
			case 'search':
				this.getProductList({
					detail: {
						searchString,
					},
				});
				break;
			default:
				this.getProductList({
					detail: {
						categories: [this.data.rootCategoryNumber],
					},
				});
				break;
		}

		
		const productClick = document.querySelectorAll
		('.bat-productlistings-card-details-image a[data-cta-type="internal"]');

		// Iterate over the NodeList and add an event listener to each element
		productClick.forEach(element => {
			element.addEventListener('click', (e) => {
				e.preventDefault();
				alert('Element clicked!');
			});
		});

	}

	async setIntensityLabel(intensity_attribute_code) {
		await Commerce.getProductAggregations(
			this.data.rootCategoryNumber
		).then(({ data }) => {
			const {
				products: { aggregations },
			} = data;
			const [intensity_label] = aggregations
				.filter(
					(aggregation) =>
						aggregation.attribute_code === intensity_attribute_code
				)
				.map((aggregation) => aggregation.label);
			this.data = {
				...this.data,
				intensity_label,
			};
		});
	}

	afterUpdate() {

		this.updateButtonTextConfiguratorePannelli();

		let productButtons = document.querySelectorAll('.bat-productlistings-card-buttons');
		if (window.innerWidth <= 576) {
			productButtons.forEach((button) => {
				button.children[0].classList.remove('arrow-link-dark');
				button.children[0].classList.add('button-dark');
			})
		}



		let basePrice = document.querySelectorAll('.bat-productlistings-card-prices');
		basePrice.forEach((span) => {
			if (span.children[1].textContent.includes('€')) {
				span.children[0].style.color = "red";
			}
		});



		const thisComponent = this;
		const productObjects = thisComponent.data.items;

		selectSize(productObjects, this.data);

		const productCards = thisComponent.querySelectorAll(
			'.bat-productlistings-card'
		);

		let strengthLookup = JSON.parse(
			Commerce.getSessionStorage('strength-lookup-table')
		);

		if (strengthLookup === null) {
			window.addEventListener('strength-table-available', () => {
				strengthLookup = JSON.parse(
					Commerce.getSessionStorage('strength-lookup-table')
				);
			});
		}

		if (!DISABLE_COMMERCE) {
			this.addQuantityHandlers();
			this.addCartButtonHandlers();
		}
		this.addPaginationButtonHandlers();
		this.addPaginationButtonClasses();
		this.addNotificationButtonHandlers();
		this.addCloseNotificationsButtonHandlers();
		this.addVariantSelectElementHandlers();
		this.addListItemSelectHandlers();
		this.createPopover();
		this.addPopoverShowHandlers();



		if (productCards && productObjects !== undefined) {
			// Show the disabled cart-button on all product cards by default
			const cartButtonsDisabled = thisComponent.querySelectorAll(
				'.bat-productlistings-card-buttons button[data-cta-type="button"]'
			);
			cartButtonsDisabled.forEach((cartButtonDisabled) => {
				cartButtonDisabled.classList.add('active');
			});

			// Get strength description (e.g. medium) by looking up the strength code (e.g. 6587)
			if (strengthLookup) {
				productCards.forEach((productCard) => {
					const strengthButtons = productCard.querySelectorAll(
						'.bat-productlistings-card-strengths button.bat-indicator'
					);
					strengthButtons.forEach((strengthButton) => {
						strengthLookup.forEach((entry) => {
							if (
								strengthButton.dataset.strength === entry.value
							) {
								strengthButton.classList.add(
									`strength-${entry.label
										.substr(0, entry.label.indexOf(' -'))
										.toLowerCase()}`
								);
								strengthButton.querySelector(
									'span'
								).innerHTML = `${entry.label
									.substr(0, entry.label.indexOf(' -'))
									.toLowerCase()}`;
							}
						});
					});
				});
			}

			// Bind click event for strength buttons
			productCards.forEach((productCard) => {
				const productSmallImage = productCard.querySelector(
					'.bat-productlistings-card-details-image img'
				);
				const productDesktopImage = productCard.querySelector(
					'.bat-productlistings-card-details-image source[media="(min-width: 576px)"]'
				);
				const strengthButtons = productCard.querySelectorAll(
					'.bat-productlistings-card-strengths button.bat-productlistings-card-options-variants-button'
				);

				const sizeButtons = productCard.querySelectorAll(
					".bat-producthero-configurator-configurable-options .size-container .size.button-size"
				);

				// Check if product has any settable variants

				productObjects.forEach((productObject) => {
					if (productObject.sku === productCard.dataset.sku) {
						const cartButton = productCard.querySelector(
							'.bat-productlistings-card-buttons button[data-cta-type="cart"]'
						);
						const cartButtonDisabled = productCard.querySelector(
							'.bat-productlistings-card-buttons button[data-cta-type="button"]'
						);
						const hasNoStrength = productObject.strength === null;
						const hasVariants =
							productObject.variants &&
							productObject.variants.length > 0;

						if (hasNoStrength && !hasVariants) {
							$(cartButtonDisabled).removeClass('active');
							$(cartButton).addClass('active');
						}
					}
				});

				$(strengthButtons).on('click', (e) => {
					this.data.parentSku = $(e.currentTarget)
						.parents('.bat-productlistings-card')
						.data('sku');


					let selectedSize = productCard.closest(".bat-productlistings-card").querySelector(".size-container.active .size.button-size")
					let selectedColourUid = $(e.currentTarget).data('uid');

					const cartButton = productCard.querySelector(
						'.bat-productlistings-card-buttons button[data-cta-type="cart"]'
					);
					const cartButtonDisabled = productCard.querySelector(
						'.bat-productlistings-card-buttons button[data-cta-type="button"]'
					);
					const cardName = productCard.querySelector(
						'.bat-productlistings-card-name a'
					);
					const cardFinalPrice = productCard.querySelector(
						'.bat-productlistings-card-prices span.final-price'
					);

					const cardBasePrice = productCard.querySelector(
						'.bat-productlistings-card-prices span.base-price'
					);

					const cardDescription = productCard.querySelector(
						'.bat-productlistings-card-description'
					);

					// Set product image for the selected strength on a configurable product
					productObjects.forEach((productObject) => {
						if (
							productObject.__typename ===
							'ConfigurableProduct' &&
							productObject.sku === this.data.parentSku.toString()
						) {
							const productVariants = productObject.variants;


							productVariants.forEach((productVariant) => {


								if (selectedSize) {

									let selectedSizeUid = selectedSize.getAttribute("data-uid");

									productVariants.forEach((productVariant) => {

										if (productVariant.attributes[0].uid == selectedColourUid && productVariant.attributes[1].uid == selectedSizeUid) {
											this.data.selectedSku = productVariant.product.sku;

											selectedSku_dictionary[this.data.parentSku.toString()] = {
												"selectedSku": productVariant.product.sku,
												"selectedProduct": productVariant.product,
												"productName": productVariant.product.name,
												"colourUid": selectedColourUid,
												"sizeUid": selectedSizeUid,
											}
											localStorage.setItem('SELECTEDPRODUCTSKU', JSON.stringify(selectedSku_dictionary));
										}

									});
								} else {

									var productObj;

									this.data.selectedSku = $(e.currentTarget).data('sku');

									if (productVariant.product.sku == this.data.selectedSku) {
										productObj = productVariant.product
									}
									

									let firstSizeContainer=productCard.closest(".bat-productlistings-card").querySelector(".size-container")
									if(firstSizeContainer){
										firstSizeContainer.classList.add("active");  //rendo active la prima size disponibile
									}

									if(productObj){
										selectedSku_dictionary[this.data.parentSku.toString()] = {
											"selectedSku": this.data.selectedSku,
											"selectedProduct": productObj,
											"productName": productObj.name,
											"colourUid": "",
											"sizeUid": "",
										}
										localStorage.setItem('SELECTEDPRODUCTSKU', JSON.stringify(selectedSku_dictionary));
									}
									

								}

								if (productVariant.product.sku === this.data.selectedSku.toString()) {

									$(productSmallImage).attr(
										'src',
										productVariant.product.small_image.url
									);
									$(productDesktopImage).attr(
										'srcset',
										productVariant.product.image.url
									);

									$(cardName).text(
										productVariant.product.name
									);

									$(cardDescription).html(
										productVariant.product.short_description
											.html
									);

									let price_range = {
										final_price: formatPriceString(
											productVariant.product.price_range
												.minimum_price.final_price
										),
                                        regular_price: formatPriceString(
                                            productVariant.product.price_range
                                           		.minimum_price.regular_price
                                        ),
									};

									/*if (
										productVariant.product.price_range
											.minimum_price.discount.amount_off
									) {
										price_range = {
											final_price: formatPriceString(
												productVariant.product.price_range
													.minimum_price.final_price
											),
											regular_price: formatPriceString(
												productVariant.product.price_range
													.minimum_price.regular_price
											),
										};

										$(cardBasePrice).html(
											price_range.regular_price.value
										);
									}*/

									if (
										productVariant.product.lowest30days_price
										&& productVariant.product.lowest30days_price > productVariant.product.price_range.minimum_price.final_price.value
									) {
										const lowestPriceObj = {
											currency: productVariant.product.price_range.minimum_price.final_price.currency,
											value: productVariant.product.lowest30days_price
										}
										price_range = {
											final_price: formatPriceString(
												productVariant.product.price_range
													.minimum_price.final_price
											),
											regular_price: formatPriceString(
												lowestPriceObj
											),
										};

										$(cardBasePrice).html(
											price_range.regular_price.value
										);
									}

									$(cardFinalPrice).html(
										price_range.final_price.value
									);
								}
							});
						}
					});

					$(strengthButtons).removeClass('active');
					$(e.currentTarget).addClass('active');

					if (productCard.dataset.stock_status !== 'OUT_OF_STOCK') {
						$(cartButtonDisabled).removeClass('active');
						$(cartButton).addClass('active');
					}
				});



				if ($(strengthButtons).length === 1) {
					$(strengthButtons).addClass('full-width');
					// $(strengthButtons).trigger('click'); // -----| Automatically select (click) strength button if there is only one
				}

				if(productCard.dataset.sku === 'glo-hyper-pro') {
					strengthButtons.forEach((el) => {
						if (el.getAttribute('title') === 'Jade Teal') {
							el.dispatchEvent(new Event('click'));
							return;
						}
					});
				} else {
					$(strengthButtons[0]).click();
				}
			});

			// Update filter-results count ('Showing # flavours')
			const filterResults_count = productObjects.length;
			let filterResults_text = thisComponent.data.filterResultsText;

			if (filterResults_count && filterResults_text) {
				if (this.notFiltered) {
					let devices = 0;
					for (let i = 0; i <= filterResults_count; i++) {
						if(productObjects[i].isFirstAccessory) break;
						devices++;
					}
					const mainText = filterResults_text.replace(
						'[count]', // |--- '[count]' is authored static-text (including the brackets)
						devices
					);
					const secondaryText = filterResults_text.replace(
						'[count]', // |--- '[count]' is authored static-text (including the brackets)
						filterResults_count - devices
					);
					$('.bat-filter-count.main-count').html(mainText);
					$('.bat-filter-count.secondary-count').html(secondaryText);
				} else {
					filterResults_text = filterResults_text.replace(
						'[count]', // |--- '[count]' is authored static-text (including the brackets)
						filterResults_count
					);
					$('.bat-filter-count').html(filterResults_text);
				}
			}
		}
	}

	unload() {
		this.removeCartButtonHandlers();
		this.removePaginationButtonHandlers();
		this.removeCloseNotificationsButtonHandlers();
		this.removeVariantSelectElementHandlers();

		document.removeEventListener(
			'filterPLP',
			this.productListListener,
			false
		);
	}


	updateItemsPrice(old_items, new_items) {
		old_items.map(o_item => {
			new_items.some(n_items => {
				if (n_items.sku === o_item.sku) {
					if (n_items.__typename == "ConfigurableProduct"){
						o_item.price_range.minimum_price = n_items.variants[0].product.price_range.minimum_price;
					} else {
						o_item.price_range.minimum_price = n_items.price_range.minimum_price;
					}
				}
			})
		})
	}


	updateButtonTextConfiguratorePannelli(){
		let cta = document.querySelector("[data-sku=configuratore-pannelli] .bat-productlistings-card-buttons span");
		let price = document.querySelector("[data-sku=configuratore-pannelli] .bat-productlistings-card-prices");
		if(cta && price){
			cta.innerHTML="Configura il tuo";
			price.style.display="none"
			document.querySelector("[data-sku=configuratore-pannelli] .price-rating").style.display="none";
			document.querySelector("[data-sku=configuratore-pannelli]").style.minHeight="150px"
		}
	}

	async getProductList(event) {
		this.page = event.detail.page || 1;
		this.categories = event.detail.categories || this.categories;
		this.properties = event.detail.properties || this.properties;
		this.priceRange = event.detail.priceRange || this.priceRange;
		this.sortParam = event.detail.sortParam || this.currentSortParam;
		this.sku = event.detail.sku || this.sku;
		this.searchString = event.detail.searchString || this.searchString;
		const customCategories = event.detail.customCategories || [];

		// const isLoggedIn = Utils.getCookie('commerce-auth-verify') === 'true';
		 const customerDetails = await Commerce.getCustomerDetails();
		 const isLoyalty = customerDetails.data?.customer?.global_loyalty_optin == '1'

		this.data = {
			...this.data,
			loading: true,
			isLoyalty,
		};

		let itemsWithUpdatedPrices;

		this.renderComponent(this.data);

		const loggedIn = Utils.getCookie('commerce-auth-verify') === 'true';

		this.categories.push(324)
		const productRequest = await Commerce.getProductCatalog(
			this.categories,
			this.properties,
			this.sku,
			this.page,
			this.data.pageSize,
			this.sortParam,
			this.priceRange,
			this.searchString
		);

		/*if(loggedIn){
			const productPricesRequest = await Commerce.getProductsPrices(
				this.categories,
				this.sortParam,
				this.page,
				this.data.pageSize);
			
			if (productPricesRequest && !productPricesRequest.errors) {
				itemsWithUpdatedPrices = productPricesRequest.data.products.items;
			}
		}*/

		if (productRequest && !productRequest.errors) {
			const items =
				this.data.dataSource === 'related_products'
					? productRequest.data.products.items[0].related_products
					: productRequest.data.products.items;

			/*if(loggedIn){
				this.updateItemsPrice(items,itemsWithUpdatedPrices)
			}*/
			
			const newItems = items.map((item) => { 
                item.shipping_group == "hog_accessories" && (item.hogAccessory = true)
            });

			const data = {
				...productRequest.data.products,
				newItems,
				customCategories
			};
			this.mapToFrontend(data);
		} else {
			const { errors } = productRequest;
			this.data = {
				...this.data,
				loading: false,
				errors,
			};
			this.renderComponent(this.data);
		}
	}

	async handlePagination(event) {
		const parent = event.currentTarget.parentElement.closest(
			'.bat-pagination-button-wrapper'
		);

		let { page } = parent.dataset;

		if (this.data.dataSource === 'related_products') {
			let current_page;
			let items;
			switch (page) {
				case 'prev':
					current_page =
						this.data.page_info.current_page > 1
							? this.data.page_info.current_page - 1
							: this.data.page_info.current_page;
					items = this.data.products.slice(
						current_page * this.data.pageSize - this.data.pageSize,
						current_page * this.data.pageSize
					);
					break;
				case 'next':
					current_page =
						this.data.page_info.current_page <
							this.data.page_info.total_pages
							? this.data.page_info.current_page + 1
							: this.data.page_info.current_page;
					items = this.data.products.slice(
						this.data.page_info.current_page * this.data.pageSize,
						current_page * this.data.pageSize
					);
					break;
				default:
					break;
			}

			this.data = {
				...this.data,
				items,
				page_info: {
					...this.data.page_info,
					current_page,
				},
			};

			this.renderComponent(this.data);
		} else {
			switch (page) {
				case 'prev':
					page =
						this.data.page_info.current_page > 1
							? this.data.page_info.current_page - 1
							: this.data.page_info.current_page;
					break;
				case 'next':
					page =
						this.data.page_info.current_page <
							this.data.page_info.total_pages
							? this.data.page_info.current_page + 1
							: this.data.page_info.current_page;
					break;
				default:
					page = parent.dataset.page;
			}
			this.getProductList({
				detail: { page, sortParam: this.sortParam },
			});
		}
	}

	createPopover() {
		const productCards = [
			...this.querySelectorAll('.bat-productlistings-card'),
		];

		this.popoverInstances = productCards
			// eslint-disable-next-line consistent-return,array-callback-return
			.map((el) => {
				const button = el.querySelector(
					'button[data-popover-button="true"]'
				);
				const tooltip = el.querySelector(
					'div[data-popover-content="true"]'
				);

				if (button !== null && tooltip !== null) {
					const popoverInstance = Popper.createPopper(
						button,
						tooltip,
						{
							placement: 'bottom',
							modifiers: [
								{
									name: 'offset',
									options: {
										offset: [0, 10],
									},
								},
							],
						}
					);
					return {
						el,
						popoverInstance,
					};
				}
			})
			.filter(Boolean);
	}

	// eslint-disable-next-line class-methods-use-this
	handleShowPopover(event, popoverInstance) {
		event.stopPropagation();
		popoverInstance.state.elements.popper.setAttribute('data-show', '');
		popoverInstance.update();
	}

	// eslint-disable-next-line class-methods-use-this
	handleHidePopover(event, popoverInstance) {
		event.stopPropagation();
		popoverInstance.state.elements.popper.removeAttribute('data-show');
	}

	addPopoverShowHandlers() {
		this.popoverInstances.forEach(({ popoverInstance }) => {
			const { reference, popper } = popoverInstance.state.elements;
			[reference, popper].forEach((el) => {
				el.addEventListener('mouseenter', (event) =>
					this.handleShowPopover(event, popoverInstance)
				);
				el.addEventListener('mouseleave', (event) => {
					this.handleHidePopover(event, popoverInstance);
				});
				el.addEventListener('focus', (event) =>
					this.handleShowPopover(event, popoverInstance)
				);
				el.addEventListener('blur', (event) => {
					this.handleHidePopover(event, popoverInstance);
				});
			});
		});
	}


	static updateCartTimer() {
		
		let minutes = Math.floor(startingTime / 60);
		let seconds = startingTime % 60;

		let timeFormatted = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
		$(".bat-header-cart-timer").text(`Completa l'ordine in ${timeFormatted}`);
		$(".bat-header-timer").css("display","block");
		window.innerWidth < 500 && $(".bat-header-loyalty-info").css("display","none");
		startingTime= startingTime-1;
		localStorage.setItem('cart-timer',startingTime);

		if (startingTime < 0) {
			clearInterval(cart_timer_interval);
			$(".bat-header-cart-timer").text("");
			window.innerWidth < 500 && $(".bat-header-loyalty-info").css("display","flex") && $(".bat-header-timer").css("display","none");
		}
	}
 	


	static async handleAddToCart(event) {

		const ele = event.currentTarget;
		const eleHTML = ele.innerHTML;
		const processingMessage = '<span>Sačekaj...</span>';
		const configurableMessageColour = '<span>Izaberi boju</span>'
		const configurableMessageSize = '<span>Izaberi veličinu</span>'
		let blocked = false;

		// const successMessage = `<span>${this.data.addToCartSuccessLabel}</span>`;

		// Set quantity since component does not have quantity controls
		const quantity = 1;

		ele.setAttribute('disabled', true);

		const parentElement = ele.closest('.bat-productlistings-card');
		const colorSelections = $(parentElement).find('.bat-productlistings-card-options-variants-button');

		let selectedSku = '';


		const sizeSelection = $(parentElement).find(".bat-producthero-configurator-configurable-options");

		const sizeSelected = $(parentElement).find(".bat-producthero-configurator-configurable-options").find(".size-container.active");

		let currentParentSku = parentElement.dataset.sku
		let localStorageDictionarySelectedSku = JSON.parse(localStorage.getItem("SELECTEDPRODUCTSKU"));

		if (colorSelections.length > 0) {
			const selectedColor = $(parentElement).find('.bat-productlistings-card-options-variants-button.active');
			if (selectedColor.length > 0) {
				selectedSku = localStorageDictionarySelectedSku[currentParentSku].selectedSku;
			}

		} else {
			//if sizeButtons not exist 
			if (sizeSelected.length > 0) {

				if (localStorageDictionarySelectedSku && localStorageDictionarySelectedSku[currentParentSku]) {
					selectedSku = localStorageDictionarySelectedSku[currentParentSku].selectedSku;
				}

			} else {
				if (sizeSelection.length == 0) {
					selectedSku = parentElement.dataset.sku;
				}
			}

		}

		const localStorageSelectedSkuPrice = JSON.parse(
			localStorage.getItem('SELECTEDPRODUCTSKU')
		);
		let itemSKU =  parentElement.dataset.sku;
		//const localStorageSelectedSkuPrice = localStorage.getItem("SELECTEDPRODUCTSKU");
		const selectedSkuValue = localStorageSelectedSkuPrice[itemSKU].selectedProduct.price_range.minimum_price.final_price.value;
		console.log('selectedSku = ' + selectedSkuValue);


		const response = await Commerce.getLoyaltyRestrictedSkus()
		if (response.length > 0) {
			const loggedIn = Utils.getCookie('commerce-auth-verify') === 'true';

			const isAuthorMode = this.classList.contains('author-mode');

			//fare un check anche se l'utente oltra ad essere loggato ha loyalty_optin=1
			if (!isAuthorMode) {
				const customerDetails = await Commerce.getCustomerDetails();

				if (!loggedIn || customerDetails.data.customer.global_loyalty_optin != '1') {
					if ((parentElement.dataset.sku && response.indexOf(parentElement.dataset.sku) > -1) || (selectedSku && response.indexOf(selectedSku) > -1)) {
						ele.setAttribute('disabled', 'disabled');
						ele.innerHTML = '<span>Riservato House of gloers</span>';
						setTimeout(() => {
							ele.innerHTML = eleHTML;
							ele.removeAttribute('disabled');
						}, 2000);
						blocked = true;
					}
				}
			}
		}
		if (!blocked) {
			if (selectedSku) {
				ele.innerHTML = processingMessage;

				var product = this.data.items.find(
					(item) => item.selectedProduct.sku === selectedSku
				);


				if (!product) {
					VARIANT_PRODUCT = true;
					product = localStorageDictionarySelectedSku[currentParentSku].selectedProduct;

				} else {
					VARIANT_PRODUCT = false;
				}

				const bundleOptions =
					product.__typename === 'BundleProduct'
						? product.items.map((item) => {
							return {
								quantity: 1,
								id: parseInt(item.option_id, 10),
								value: item.options.map((option) =>
									option.id.toString()
								),
							};
						})
						: '';

				const cartObject = JSON.parse(
					localStorage.getItem('commerce-cart-storage')
				);

				

				const cartItems = cartObject && cartObject.data.cart.items;

				if(VARIANT_PRODUCT) {

					const cartProduct =
						cartItems &&
						cartItems.find(
							(item) => item.product.sku === product.sku
						);

					if(cartProduct && cartProduct.quantity >= +product.max_qty) {
						const notificationElement = this.constructor.cartMessageElements.find(
							(element) => element.dataset.sku === product.sku
						);
						notificationElement.style.display = 'flex';
						const messageElement = notificationElement.querySelector(
							'.message'
						);
						messageElement.innerText = this.data.addToCartMaxQtyLabel;
						return;
					}

					

					/* eslint-disable-next-line no-unused-vars */
					const req = await Commerce.addItemToCart({
						bundleOptions,
						familySku: parentElement.dataset.sku,
						productName: product.name,
						id: product.id.toString(),
						productQuantity: quantity.toString(),
						productSku: selectedSku,
						price: selectedSkuValue,
						productType: product.__typename,
						is_wallet_product: product.is_wallet_product
					});

					// ele.innerHTML = successMessage;

					
					setTimeout(() => {
						ele.innerHTML = eleHTML;
						ele.removeAttribute('disabled');
						window.scrollTo({ top: 0, behavior: 'smooth' });
						if(!req || (req && !req.cartMessage)) {
							let expires_at = req.data.cart.cart_expiry.expires_at;
							let expires_at_date = new Date(expires_at.replace(/\s+/g, 'T') + 'Z');
							let today = new Date();
							let differenzaInMillisecondi = Math.abs(expires_at_date-today);
							let differenceInSeconds = Math.floor(differenzaInMillisecondi/ 1000); // 1 secondo = 1000 
							startingTime= differenceInSeconds;
							localStorage.setItem('cart-timer',startingTime);
							if(cart_timer_interval != null){
								clearInterval(cart_timer_interval)
							}
							cart_timer_interval = setInterval(this.constructor.updateCartTimer.bind(), 1000);
							$(".bat-minicart-gloit").removeClass("d-none");
						}
					}, 1000);
				} else {
					const cartProduct =
						cartItems &&
						cartItems.find(
							(item) => item.product.sku === product.selectedProduct.sku
						);

					if(cartProduct && cartProduct.quantity >= +product.max_qty) {
						const notificationElement = this.constructor.cartMessageElements.find(
							(element) => element.dataset.sku === product.selectedProduct.sku
						);
						notificationElement.style.display = 'flex';
						const messageElement = notificationElement.querySelector(
							'.message'
						);
						messageElement.innerText = this.data.addToCartMaxQtyLabel;
						return;
					}

					/* eslint-disable-next-line no-unused-vars */
					const req = await Commerce.addItemToCart({
						bundleOptions,
						familySku: parentElement.dataset.sku,
						productName: product.selectedProduct.name,
						id: product.selectedProduct.id.toString(),
						productQuantity: quantity.toString(),
						productSku: selectedSku,
						price: selectedSkuValue,
						productType: product.selectedProduct.__typename,
						is_wallet_product: product.is_wallet_product
					});

					// ele.innerHTML = successMessage;

					setTimeout(() => {
						ele.innerHTML = eleHTML;
						ele.removeAttribute('disabled');
						window.scrollTo({ top: 0, behavior: 'smooth' });
						if(!req || (req && !req.cartMessage)) {
							let expires_at = req.data.cart.cart_expiry.expires_at;
							let expires_at_date = new Date(expires_at);
							expires_at_date.setTime(expires_at_date.getTime() + 2 * 60 * 60 * 1000); //aggiungo 2 ore a causa del orario in GTM
							let today = new Date();
							let differenzaInMillisecondi = Math.abs(expires_at_date-today);
							let differenceInSeconds = Math.floor(differenzaInMillisecondi/ 1000); // 1 secondo = 1000 
							startingTime= differenceInSeconds;
							localStorage.setItem('cart-timer',startingTime);
							if(cart_timer_interval != null){
								clearInterval(cart_timer_interval)
							}
							cart_timer_interval = setInterval(this.constructor.updateCartTimer.bind(), 1000);

							$(".bat-minicart-gloit").removeClass("d-none");
						}
					}, 1000);
				}
				// if (req && req.errors) {
				// 	const notificationElement = this.constructor.cartMessageElements.find(
				// 		(element) => element.dataset.sku === product.selectedProduct.sku
				// 	);
				// 	notificationElement.style.display = 'flex';
				// 	const messageElement = notificationElement.querySelector(
				// 		'.message'
				// 	);

				// 	messageElement.innerText = req.errors[0].message;
				// }
			} else {
				if (colorSelections.length > 0) {
					ele.innerHTML = configurableMessageColour;
				} else {
					if (sizeSelection.length > 0) {
						ele.innerHTML = configurableMessageSize;
					}
				}

				setTimeout(() => {
					ele.innerHTML = eleHTML;
					ele.removeAttribute('disabled');
				}, 2000);
			}
		}

	}

	static handleCloseCartNotification(event) {
		const notificationElement = this.constructor.cartMessageElements.find(
			(element) => element.dataset.sku === event.currentTarget.dataset.sku
		);
		notificationElement.style.display = 'none';
		const messageElement = notificationElement.querySelector('.message');
		messageElement.innerText = '';
	}

	async handleSetNotification(event) {
		event.stopPropagation();
		const { sku } = event.currentTarget.parentElement.dataset;
		const parentProductIndex = this.data.items.findIndex(
			(item) => item.selectedProduct.sku === sku
		);
		let parentProduct = this.data.items[parentProductIndex];

		// LOGIC NEEDED HERE TO MAKE
		const req = await Commerce.addProductAlert(
			parentProduct.selectedProduct.id
		);

		if (req && req.data && req.data.productAlertCustomerNotifyInStock) {
			if (parentProduct.sku === sku) {
				parentProduct = {
					...parentProduct,
					notification_set: true,
					selectedProduct: {
						...parentProduct.selectedProduct,
						notification_set: true,
						show_notify_button: false,
						show_notify_set_button: true,
					},
				};
			} else {
				parentProduct = {
					...parentProduct,
					variants: [
						...parentProduct.variants.map((variant) => {
							if (variant.product.sku === sku) {
								return {
									...variant,
									product: {
										...variant.product,
										notification_set: true,
										show_notify_button: false,
										show_notify_set_button: true,
									},
								};
							}
							return variant;
						}),
					],
					selectedProduct: {
						...parentProduct.selectedProduct,
						notification_set: true,
						show_notify_button: false,
						show_notify_set_button: true,
					},
				};
			}

			this.data = {
				...this.data,
				items: [
					...this.data.items.slice(0, parentProductIndex),
					parentProduct,
					...this.data.items.slice(
						parentProductIndex + 1,
						this.data.items.length
					),
				],
			};

			this.renderComponent(this.data);
		}
	}

	handleVarientChange(event) {
		const parentElement = event.currentTarget.closest('.bat-productlistings-card');
		const parentSku = parentElement.dataset.sku;
		const sku = event.currentTarget.dataset.sku;
		const parentProduct = this.data.items.find(
			(product) => product.sku === parentSku
		);
		const parentProductIndex = this.data.items.findIndex(
			(product) => product.sku === parentSku
		);
		const selectedProduct = parentProduct.variants.find(
			(variant) => variant.product.sku === sku
		);
		this.data = {
			...this.data,
			items: [
				...this.data.items.slice(0, parentProductIndex),
				{
					...parentProduct,
					selectedProduct: {
						...selectedProduct?.product,
						...this.constructor.mapProduct(selectedProduct?.product),
					},
				},
				...this.data.items.slice(
					parentProductIndex + 1,
					this.data.items.length
				),
			],
		};
	}

	handleItemClick(event) {
		let clickedItem = event.currentTarget.parentElement.parentElement;
		let clickedItemsku = clickedItem.parentElement;
		const skuId = clickedItemsku.dataset;
		
		const parentProduct = this.data.items.find(
			(product) => product.sku === skuId.sku
		);

		const productObject = parentProduct;

		if (typeof dataLayer !== 'undefined') {
			dataLayer.push({
				event: 'select_item',
				ecommerce: {
					items: [
						{
							item_name: productObject.name,
							item_id: productObject.id.toString(),
							price: productObject.variants[0].product.price_range.maximum_price.final_price.value.toFixed(2),
							item_brand: 'Glo',
							item_category: productObject.categories[0].name,
							item_category_2: '',
							item_category_3: '',
							item_category_4: '',
							item_variant: productObject.variants[0].attributes[0].label,
							item_list_name: '',
							item_list_id: '',
							index: '1',
							quantity: '1'
						},
					],
				},
			});
		}
	}

	static mapProduct(product) {
		if (!product) return;
		function formatPriceString(priceObject) {
			const rootCategory = document.querySelector(".bat-plp-filter-container").getAttribute("rootcategory");
			let value = '';
			if (typeof priceObject.value === 'string') {
				value = priceObject.value;
			} else if (rootCategory == '324') {
				value = `
					<img class="bat-minicart-gloit-subtotal-coin" src="/content/dam/glo-rs/images/minicart/minicart-coin.svg" alt="coin" width="21" height="25">
					${priceObject.value}
				`;
			} else if (product.shipping_group == "hog_accessories") {
				value = priceObject.value
			}
			else {
				value = 'CENA: '+Commerce.priceFormatter(parseFloat(priceObject.value).toFixed(2), priceObject.currency);
			}
			return {
				...priceObject,
				value: value
			};
		}

		let small_image;
		switch (product.type_id) {
			case 'configurable':
				small_image =
					product.small_image ||
					product.variants[0].product.small_image;
				break;
			case 'bundle':
				small_image =
					product.small_image ||
					product.items[0].options[0].product.small_image;
				break;
			default:
				small_image = product.small_image;
		}

		let price_range = {
			final_price: formatPriceString(
				product.price_range.minimum_price.final_price
			),
            regular_price: formatPriceString(
					product.price_range.minimum_price.regular_price
				),
		};

		/*if (product.price_range.minimum_price.discount.amount_off) {
			price_range = {
				final_price: formatPriceString(
					product.price_range.minimum_price.final_price
				),
				regular_price: formatPriceString(
					product.price_range.minimum_price.regular_price
				),
			};
		}*/

		if (
			product.lowest30days_price
			&& product.lowest30days_price > product.price_range.minimum_price.final_price.value
		) {
			const lowestPriceObj = {
				currency: product.price_range.minimum_price.final_price.currency,
				value: product.lowest30days_price
			}
			price_range = {
				final_price: formatPriceString(
					product.price_range
						.minimum_price.final_price
				),
				regular_price: formatPriceString(
					lowestPriceObj
				),
			};
		}

		return {
			price_range,
			show_notify_button:
				product.stock_status === 'OUT_OF_STOCK' &&
				!product.notification_set,
			show_notify_set_button:
				product.stock_status === 'OUT_OF_STOCK' &&
				product.notification_set,
			show_link_button:
				product.stock_status !== 'OUT_OF_STOCK' &&
				product.type_id === 'bundle',
			show_cart_button:
				product.stock_status !== 'OUT_OF_STOCK' &&
				product.type_id !== 'bundle' &&
				product.type_id !== 'configurable',
			small_image,
		};
	}

	mapSelected(products) {
		return products.map((product) => {
			const selectedProduct = product;

			let switches;

			if (product.configurable_options) {
				switches = product.configurable_options.reduce((acc, curr) => {
					return {
						...acc,
						[curr.attribute_code]: {
							...curr,
							values: {
								...curr.values.map((value) => ({
									...value,
									...product.variants.filter((variant) =>
										variant.attributes.some(
											(attrib) => attrib.uid === value.uid
										)
									)[0],
								})),
							},
						},
					};
				}, {});
			}
			return {
				...product,
				...this.constructor.mapProduct(product),
				selectedProduct: {
					...selectedProduct,
					...this.constructor.mapProduct(selectedProduct),
				},
				switches,
			};
		});
	}

	mapToFrontend(queryResponse) {

		const customCategories = queryResponse.customCategories;
		const categoryTitle = $('.bat-category-title');

		if (!customCategories || customCategories.length === 0) {
			this.notFiltered = true;
			queryResponse.items = this.sortProductsWithoutCategory(queryResponse.items);
			categoryTitle.removeClass('uk-invisible');
		} else {
			this.notFiltered = false;
			queryResponse.items = this.sortProductsWithCustomCategories(queryResponse.items, customCategories);
			categoryTitle.addClass('uk-invisible');
		}

		const products =
			this.data.dataSource === 'related_products'
				? this.mapSelected(queryResponse.items[0].related_products)
				: this.mapSelected(queryResponse.items);

		const items =
			this.data.dataSource === 'related_products'
				? this.mapSelected(
					queryResponse.items[0].related_products
				).slice(0, this.data.pageSize)
				: this.mapSelected(queryResponse.items);

		const page_info =
			this.data.dataSource === 'related_products'
				? {
					total_pages: Math.ceil(
						products.length / this.data.pageSize
					),
					current_page: 1,
					page_size: this.data.pageSize,
				}
				: queryResponse.page_info;

		this.data = {
			...this.data,
			items,
			products,
			page_info: {
				...page_info,
				pages: [...Array(page_info.total_pages).keys()].map((page) => ({
					page: page + 1,
				})),
			},
			errors: null,
			loading: false
		};

		// SET PDP URL FOR EACH PRODUCT
		// Define the href value to use for links to the Product Detail page

		/* eslint-disable-next-line array-callback-return */
		this.data.items.map((item) => {
			const rootCategory = document.querySelector(".bat-plp-filter-container").getAttribute("rootcategory");
			const urlKey = item.url_key;
			const urlPath = rootCategory == '324' ? this.data.productHeroUrl.replace("shop", "gloers-shop") : this.data.productHeroUrl;
			let urlSuffix;

			urlKey && urlKey !== ''
				? (urlSuffix = `/${urlKey}`)
				: (urlSuffix = `?product_sku=${item.selectedProduct.sku}`);

			item.productHeroUrl = urlPath + urlSuffix; // href for link to PDP
		});

		this.renderComponent(this.data);
	}

	sortProductsWithoutCategory(items) {
		const rootCategory = document.querySelector(".bat-plp-filter-container").getAttribute("rootcategory");
		const devices = [];
		const others = [];
		for (const product of items) {
			const productCategories = product.categories;
			if (productCategories && productCategories.length > 0 && product.sku !== 'Hyperplus' && product.__typename !== 'VirtualProduct') {
				const categoryNames = new Set(productCategories.map(el => el.name));
				categoryNames.delete('Shop');
				if (categoryNames.size === 0 || categoryNames.has('UREĐAJI') || product.name.indexOf('hyper+') > -1) {
					devices.push(product);
				} else {
					if(product.shipping_group == "hog_accessories") {
						others.unshift(product)
					}
					else {
						others.push(product);
					}
				}
			} else if (product.__typename === 'VirtualProduct' && rootCategory == '324') {
				if(product.shipping_group == "hog_accessories") {
					others.unshift(product)
				}
				else {
					others.push(product);
				}
			}
		}
		if (others.length > 0) {
			others[0].isFirstAccessory = true;
		}
		if (devices.length > 0 || others.length > 0) {
			return [...devices, ...others];
		}
		return items;
	}

	sortProductsWithCustomCategories(items, customCategories) {
		const rootCategory = document.querySelector(".bat-plp-filter-container").getAttribute("rootcategory");
		const devices = [];
		let others = [];
		const deviceSkus = new Set();
		const otherSkus = new Set();
		for (const customCategory of customCategories) {
			const customCategoryProducts = [];
			for (const product of items) {
				const productCategories = product.categories;
				if (productCategories && productCategories.length > 0 && product.sku !== 'Hyperplus' && (product.__typename !== 'VirtualProduct')) {
					const categoryNames = new Set(productCategories.map(el => el.name));
					categoryNames.delete('Shop');
					if (customCategory === 'UREĐAJI' && (categoryNames.size === 0 || categoryNames.has(customCategories) || product.name.indexOf('hyper+') > -1)) {
						devices.push(product);
						deviceSkus.add(product.sku);
					}
					if (categoryNames.has(customCategory) && !deviceSkus.has(product.sku) && !otherSkus.has(product.sku)) {
						if (categoryNames.has('UREĐAJI') || categoryNames.size === 0 || product.name.indexOf('hyper+') > -1) {
							devices.push(product);
							deviceSkus.add(product.sku);
						} else {
							customCategoryProducts.push(product);
							otherSkus.add(product.sku);
						}
					}
				} else if (product.__typename === 'VirtualProduct' && rootCategory == '324') {
					customCategoryProducts.push(product);
					otherSkus.add(product.sku);
				}
			}
			others = [...others, ...customCategoryProducts];
		}
		return [...devices, ...others];
	}

	renderComponent(data) {
		this.setAttribute('data-model', JSON.stringify(data));

		// Track Product View
		if (!data.loading && data.items && data.items.length > 0) {
			const productObjects = data.items;
			const productImpressions = [];


			/* eslint-disable-next-line array-callback-return */
			productObjects.map((product, index) => {
				productImpressions.push({
					item_name: product.name,
					item_id: product.id.toString(),
					price: productObjects[index].variants[index].product.price_range.maximum_price.final_price.value.toFixed(2),
					item_brand: 'Glo',
					item_category: product.categories[0].name,
					item_category_2: '',
					item_category_3: '',
					item_category_4: '',
					item_variant: '',
					item_list_name: '',
					item_list_id: '',
					index: index.toString(),
					quantity: '1'
				});
			});

			/* Google Tag Manager */
			if (typeof dataLayer !== 'undefined') {
				dataLayer.push({
					event: 'view_item_list',
					ecommerce: {
						currencyCode:
							productObjects[0].price_range.final_price.currency,
						items: productImpressions,
					},
				});
			}

			// const productsData = data.items.map((item) => {
			// 	const itemObject = {
			// 		sku: item.sku,
			// 		name: item.name,
			// 		price: item.price_range.final_price.value,
			// 	};
			// 	return itemObject;
			// });

			// const eventData = {
			// 	detail: {
			// 		products: productsData,
			// 	},
			// };

			// const productViewEvent = new CustomEvent('productView', eventData);
			// document.dispatchEvent(productViewEvent);
		}

		//window.scrollTo({ top: 0, behavior: 'auto' });

	}


	addQuantityHandlers() {
		this.constructor.quantityWrappers.forEach((ele) => {
			Forms.bindIncrementer($(ele));
		});
	}

	addCartButtonHandlers() {
		this.constructor.cartButtons.forEach((ele) => {
			if (ele.dataset.listener !== 'true') {
				ele.setAttribute('data-listener', 'true');
				ele.addEventListener(
					'click',
					this.constructor.handleAddToCart.bind(this),
					true
				);
			}
		});
	}

	removeCartButtonHandlers() {
		this.constructor.cartButtons.forEach((button) =>
			button.removeEventListener(
				'click',
				this.constructor.handleAddToCart.bind(this),
				true
			)
		);
	}

	addNotificationButtonHandlers() {
		if (this.constructor.notificationButtons)
			this.removerNotificationButtonHandlers();
		this.constructor.notificationButtons.forEach((button) =>
			button.addEventListener(
				'click',
				this.handleSetNotification.bind(this),
				false
			)
		);
	}

	removerNotificationButtonHandlers() {
		this.constructor.notificationButtons.forEach((button) =>
			button.removeEventListener(
				'click',
				this.handleSetNotification.bind(this),
				false
			)
		);
	}

	addVariantSelectElementHandlers() {
		if (this.constructor.variantSelectElements)
			this.removeVariantSelectElementHandlers();
		this.constructor.variantSelectElements.forEach((element) =>
			element.addEventListener(
				'click',
				this.handleVarientChange.bind(this),
				false
			)
		);
	}

	addListItemSelectHandlers(){
		if (this.constructor.listSelectElements)
		this.constructor.listSelectElements.forEach((element) =>
			element.addEventListener(
				'click',
				this.handleItemClick.bind(this),
				false
			)
		);
	}

	removeVariantSelectElementHandlers() {
		this.constructor.variantSelectElements.forEach((element) =>
			element.removeEventListener(
				'click',
				this.handleVarientChange.bind(this),
				false
			)
		);
	}

	addPaginationButtonHandlers() {
		if (this.constructor.paginationButtons)
			this.removePaginationButtonHandlers();
		this.constructor.paginationButtons.forEach((button) =>
			button.addEventListener(
				'click',
				this.handlePagination.bind(this),
				true
			)
		);
	}

	removePaginationButtonHandlers() {
		this.constructor.paginationButtons.forEach((button) =>
			button.removeEventListener(
				'click',
				this.handlePagination.bind(this),
				true
			)
		);
	}

	addPaginationButtonClasses() {
		this.constructor.paginationButtons.forEach((button) => {
			const parent = button.parentElement;
			if (+parent.dataset.page === this.data.page_info.current_page)
				button.classList.add('active');
			if (
				parent.dataset.page === 'prev' &&
				this.data.page_info.current_page === 1
			)
				button.classList.add('disabled');
			if (
				parent.dataset.page === 'next' &&
				this.data.page_info.current_page ===
				this.data.page_info.total_pages
			)
				button.classList.add('disabled');
		});
	}

	addCloseNotificationsButtonHandlers() {
		if (this.constructor.closeNotificationButtons)
			this.removeCloseNotificationsButtonHandlers();
		this.constructor.closeNotificationButtons.forEach((button) =>
			button.addEventListener(
				'click',
				this.constructor.handleCloseCartNotification.bind(this),
				true
			)
		);
	}

	removeCloseNotificationsButtonHandlers() {
		this.constructor.closeNotificationButtons.forEach((button) =>
			button.removeEventListener(
				'click',
				this.constructor.handleCloseCartNotification.bind(this),
				true
			)
		);
	}

}



function selectSize(productObjects, data) {
	let sizes = document.querySelectorAll('.size-container');
	let sizeLabel = $('.size-selected');




	sizes.forEach(size => {
		size.addEventListener('click', (e) => {
			sizes.forEach(size => {
				size.classList.remove('active');
			});

			size.classList.add('active');


			let selectedSize = size.querySelector('button');


			const cardName = size.closest(".bat-productlistings-card").querySelector(".bat-productlistings-card-name a");

			const productSmallImage = size.closest(".bat-productlistings-card").querySelector('.bat-productlistings-card-details-image img');

			const productDesktopImage = size.closest(".bat-productlistings-card").querySelector('.bat-productlistings-card-details-image source[media="(min-width: 576px)"]');

			const cardFinalPrice = size.closest(".bat-productlistings-card").querySelector('.bat-productlistings-card-prices span.final-price');

			const cardBasePrice = size.closest(".bat-productlistings-card").querySelector('.bat-productlistings-card-prices span.base-price');

			if (selectedSize) {
				data.parentSku = size.closest('.bat-productlistings-card').getAttribute("data-sku")

				productObjects.forEach((productObject) => {
					if (
						productObject.__typename ===
						'ConfigurableProduct' &&
						productObject.sku === data.parentSku
					) {
						const productVariants = productObject.variants;

						productVariants.forEach((productVariant) => {

							var selectedColour = size.closest('.bat-productlistings-card-options').querySelector(".bat-productlistings-card-strengths button.active")
							var selectedSizeUid = selectedSize.getAttribute("data-uid");
							if (selectedColour) {
								var selectedColourUid = selectedColour.getAttribute("data-uid");

								if (productVariant.attributes[0].uid == selectedColourUid && productVariant.attributes[1].uid == selectedSizeUid) {


									selectedSku_dictionary[data.parentSku] = {
										"selectedSku": productVariant.product.sku,
										"selectedProduct": productVariant.product,
										"productName": productVariant.product.name,
										"colourUid": selectedColourUid,
										"sizeUid": selectedSizeUid,
									}
									localStorage.setItem('SELECTEDPRODUCTSKU', JSON.stringify(selectedSku_dictionary));

									updateProductData(productVariant.product, cardName, productSmallImage, productDesktopImage, cardBasePrice, cardFinalPrice)
								}
							} else {
								if (productVariant.attributes.length > 1) {    // esiste la selezione dei colori ma nessun colore è stato scelto 

									localStorage.setItem('SELECTEDPRODUCTSKU', productVariants[0].product.name);
									size.closest('.bat-productlistings-card-options').querySelector(".bat-productlistings-card-strengths button").classList.add("active")

									updateProductData(productVariants[0].product, cardName, productSmallImage, productDesktopImage, cardBasePrice, cardFinalPrice)

								} else {		// non esiste esiste la scelta di colori 
									if (productVariant.attributes[0].uid == selectedSizeUid) {

										selectedSku_dictionary[data.parentSku] = {
											"selectedSku": productVariant.product.sku,
											"selectedProduct": productVariant.product,
											"productName": productVariant.product.name,
											"colourUid": selectedColourUid,
											"sizeUid": selectedSizeUid,
										}
										localStorage.setItem('SELECTEDPRODUCTSKU', JSON.stringify(selectedSku_dictionary));
										updateProductData(productVariant.product, cardName, productSmallImage, productDesktopImage, cardBasePrice, cardFinalPrice)
									}
								}

							}


						});
					}
				});
			}
		})
	});
}


function formatPriceString(priceObject) {
	return {
		...priceObject,
		value:
			typeof priceObject.value === 'string'
				? priceObject.value
				: 'CENA: '+Commerce.priceFormatter(
					parseFloat(priceObject.value).toFixed(2),
					priceObject.currency
				),
	};
}

function updateProductData(product, cardName, productSmallImage, productDesktopImage, cardBasePrice, cardFinalPrice) {
	$(cardName).text(
		product.name
	);

	$(productSmallImage).attr(
		'src',
		product.image.url
	);

	$(productDesktopImage).attr(
		'srcset',
		product.image.url
	);

	let price_range = {
		final_price: formatPriceString(
			product.price_range
				.minimum_price.final_price
		),
        regular_price: formatPriceString(
           	product.price_range
            	.minimum_price.regular_price
        ),
	};

	/*if (product.price_range.minimum_price.discount.amount_off) {
		price_range = {
			final_price: formatPriceString(
				product.price_range
					.minimum_price.final_price
			),
			regular_price: formatPriceString(
				product.price_range
					.minimum_price.regular_price
			),
		};

		$(cardBasePrice).html(
			price_range.regular_price.value
		);
	}*/

	if (
		product.lowest30days_price
		&& product.lowest30days_price > product.price_range.minimum_price.final_price.value
	) {
		const lowestPriceObj = {
			currency: product.price_range.minimum_price.final_price.currency,
			value: product.lowest30days_price
		}
		price_range = {
			final_price: formatPriceString(
				product.price_range
					.minimum_price.final_price
			),
			regular_price: formatPriceString(
				lowestPriceObj
			),
		};

		$(cardBasePrice).html(
			price_range.regular_price.value
		);
	}
	$(cardFinalPrice).html(
		price_range.final_price.value
	);
}


!customElements.get('bat-productlisting-glors') && customElements.define('bat-productlisting-glors', ProductListingTemplateGloBrochurewareRS);
